.c-video-provider {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;
}

// !important needed to override youtube inline styles
.c-video-provider iframe {
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	width: 100% !important;
	height: 100% !important;
}

.c-video-provider__preview-button {
	border-width: 0;
}

.c-video-provider-grid--auto-fill {
	display: flex; /* no CSS grid fallback style */
	flex-wrap: wrap; /* no CSS grid fallback style */
}

.c-video-provider-grid--auto-fill > * {
	width: 270px; /* no CSS grid fallback style */
	flex: 0 1 auto; /* no CSS grid fallback style */
	margin-right: 30px; /* no CSS grid fallback style */
}

@supports(display: grid) {
	.c-video-provider-grid--auto-fill {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(var(--column-width, 270px), 1fr));
		grid-column-gap: var(--column-gap, 30px);
		grid-row-gap: var(--row-gap, 15px);
	}

	/* remove fallback style */
	.c-video-provider-grid--auto-fill > * {
		width: auto;
		margin-right: 0;
	}
}

.c-video-provider-grid--two-or-4 {
	display: grid;
	justify-items: center;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: var(--column-gap, 30px);
	grid-row-gap: var(--row-gap, 15px);
}

@media(min-width: 615px) {
	.c-video-provider-grid--two-or-4 {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
}
